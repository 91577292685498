import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import store from '../redux/store';
// export const apiUrl = 'https://api.seoulinstitute.com/';
export const apiUrl = 'https://api.starkoreanbhasa.com/';

export const signInImage = "https://api.starkoreanbhasa.com/wp-content/uploads/2023/09/star.jpeg"
export const sideBarImage = "https://api.starkoreanbhasa.com/wp-content/uploads/2023/09/star.jpeg"
export const ExamBlockPlaceHolder = "https://api.starkoreanbhasa.com/wp-content/uploads/2023/09/star.jpeg"
export const UserPlaceHolder = "https://api.starkoreanbhasa.com/wp-content/uploads/2023/08/user-icon.jpeg"
export const Headphone = "https://api.starkoreanbhasa.com/wp-content/uploads/2023/08/headpone.png"
export const MainTitle = "Star Korean Bhasa"

export const ImageURL = `${apiUrl}media/`;

export const color1 = "#263546";
export const color2 = "#174337";
export const color3 = "#314f3d";

export const AdsGETApi = (params, slug) => {
  return axios({
    method: "GET",
    url: `${apiUrl}wp-json/wp/v2/${slug}`,
    params: params
  })
}

export const AdsGETApiWithParam = (params, slug) => (
  axios({
    method: "GET",
    url: `${apiUrl}wp-json/wp/v2/${slug}`,
    params: params
  })
)
export const AdsGETApiAuth = (params, slug) => {
  const token = store.getState().LoginState.token
  return axios({
    method: "GET",
    url: `${apiUrl}wp-json/wp/v2/${slug}`,
    params: params,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
  })
}
export const AdsGETApiWithToken = (params, slug, token) => {
  // const token = store.getState().LoginState.token
  return axios({
    method: "GET",
    url: `${apiUrl}wp-json/wp/v2/${slug}`,
    params: params,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
  })
}
export const AdsPOSTApi = (data, slug) => {
  const token = store.getState().LoginState.token;
  console.log(token)
  return axios({
    method: "POST",
    url: `${apiUrl}wp-json/wp/v2/${slug}?_fields=id,slug,title,meta`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}
export const AdsPOSTApiWithoutAuth = (data, slug) => {
  return axios({
    method: "POST",
    url: `${apiUrl}wp-json/wp/v2/${slug}?_fields=id,slug,title,meta`,
    headers: {
      // "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}
export const AdsPOSTApiWithToken = (data, slug, token) => {
  // const token = store.getState().LoginState.token;
  // console.log(token)
  return axios({
    method: "POST",
    url: `${apiUrl}wp-json/wp/v2/${slug}?_fields=id,slug,title,meta`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}
export const AdsPUTApi = async (data, slug, id) => {
  const token = store.getState().LoginState.token
  return await axios({
    method: "PUT",
    url: `${apiUrl}wp-json/wp/v2/${slug}/${id}?_fields=id,slug,title,meta`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}
export const AdsPUTApiToken = async (data, slug, id,token) => {
  // const token = store.getState().LoginState.token
  return await axios({
    method: "PUT",
    url: `${apiUrl}wp-json/wp/v2/${slug}/${id}?_fields=id,slug,title,meta`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: data
  })
}
export const AdsDeleteApi = async (slug, id) => {
  const token = store.getState().LoginState.token
  return await axios({
    method: "DELETE",
    url: `${apiUrl}wp-json/wp/v2/${slug}/${id}?_fields=id,slug,title,meta`,
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    data: {
      id: id,
      force: true
    }
  })
}

export const userSignIn = async (username, password) => (
  await axios({
    method: "POST",
    url: `${apiUrl}wp-json/jwt-auth/v1/token`,
    params: {
      username: username,
      password: password
    }
  })
)

export const UserMe = (token) => {
  // const token = store.getState().LoginState.token
  return axios({
    method: "GET",
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    url: `${apiUrl}wp-json/wp/v2/users/me`,
  })
}
export const UserMeEdit = (data) => {
  const token = store.getState().LoginState.token
  return axios({
    method: "PUT",
    headers: {
      "Authorization": `Basic ${token}`,
      // "Authorization": `Bearer ${token}`,
    },
    url: `${apiUrl}wp-json/wp/v2/users/me`,
    data: data,
  })
}

export const MediaUpload = (file) => {
  const token = store.getState().LoginState.token;
  return (
    fetch(`${apiUrl}wp-json/wp/v2/media`, {
      method: "POST",
      headers: {
        // "Authorization": `Bearer ${token}`,
        "Authorization": `Basic ${token}`,
        "Content-Type": `${file.type}`,
        'Content-Disposition': `attachment; filename = "${file.name}"`,
      },
      body: file
    })
  )
}

export const sendOTPSMS = (customerNumberSMS, SMSMessage) => {
  const token = "";
  return axios({
    method: "POST",
    url: `https://sms.sociair.com/api/sms`,
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
    data: {
      mobile: customerNumberSMS,
      message: SMSMessage
    },
  })
}

export const sendOTPEmail = (email, message) => {
  const formData = new FormData();
  formData.append("your-email", email);
  formData.append("your-subject", "One time Password(OTP)");
  formData.append("your-message", message);
  return fetch(`https://redcapubt.com/wp-json/contact-form-7/v1/contact-forms/2513/feedback`, {
    method: 'POST',
    body: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}